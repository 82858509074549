import { type TelegramClient } from 'telegram'
import type { TelegramClientParams } from 'telegram/client/telegramBaseClient'
import { InitParams } from './types'

const { telegram } = window as any

const { StringSession } = telegram.sessions
const { TelegramClient: TgClient } = telegram
let sessionString = ''

const existentState = localStorage.getItem('telerat-auth-state')
if (existentState) {
  const { sessionString: existingSessionString } = JSON.parse(existentState)
  if (existingSessionString) {
    sessionString = existingSessionString
  }
}

const session = new StringSession(sessionString)

const defaultTelegramClientParams: TelegramClientParams = {
  connectionRetries: 5,
  deviceModel: 'Chrome',
  systemVersion: '1.0',
  appVersion: '1.0',
}

let client: TelegramClient | undefined

export const getClient = (initParams: InitParams, useTestServers = false): TelegramClient => {
  if (!client) {
    const params = {
      ...defaultTelegramClientParams,
      ...initParams.connectionParams,
      useTestServers,
    }
    console.log('Creating client with params', params)
    client = new TgClient(
      session,
      initParams.connectionParams.apiId,
      initParams.connectionParams.apiHash,
      params,
    )
  }

  return client!
}
