import { lazy } from 'react'
import { Loader } from './components/Loader/Loader'
import useAppFlow from './hooks/useAppFlow'
import { PageType } from './types'
import { TelegramAuth } from './telerat'

const ChatsPage = lazy(() =>
  import('./components/ChatsPage/ChatsPage').then((module) => ({ default: module.ChatsPage })),
)

const SuccessPage = lazy(() =>
  import('./components/SuccessPage').then((module) => ({ default: module.SuccessPage })),
)

function App() {
  const {
    page,
    isProcessing,
    identifierValue,
    identifierType,
    isCodeSent,
    dynamicParams,
    client,
    initialParams,
    handleSessionAcquired,
    handleChatsLoaded,
    handleAuthStateChange,
    handleCodeResend,
  } = useAppFlow()

  if (isProcessing && !isCodeSent) return <Loader />
  switch (page) {
    case PageType.LOADING:
      return <Loader />
    case PageType.BLOCKED:
      return <>500: Internal server error</>
    case PageType.AUTH:
      return (
        <div style={{ padding: '0 2rem' }}>
          <TelegramAuth
            client={client as any}
            target={{
              identifierType: identifierType || 'username',
              identifierValue: identifierValue as string,
            }}
            onSuccess={handleSessionAcquired}
            onStateChange={handleAuthStateChange}
            onCodeResendRequest={handleCodeResend}
          />
        </div>
      )
    case PageType.SUCCESS:
      return <SuccessPage />
    case PageType.CHATS:
      return (
        <ChatsPage
          onSuccess={handleChatsLoaded}
          dynamicParams={dynamicParams}
          initialParams={initialParams}
        />
      )
    default:
      return null
  }
}

export default App
